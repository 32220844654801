import { css } from '@emotion/core';
import styled from '@emotion/styled';
import Theme from '@theme';
import { mediaQuery } from '@utils';
import { FunctionComponent } from 'react';

const withThemeColor = ({ color = 'none' }) => css`
  color: ${Theme.colors[color] || 'inherit'};
`;

const BaseText = styled.p`
  ${withThemeColor};
  font-family: Mont;
  font-size: ${Theme.sizes.text.p};
  line-height: 3.6rem;
  margin-bottom: 3rem;
  text-align: justify;
`;

const LargeHero = styled.div`
  ${withThemeColor};
  font-size: ${Theme.sizes.text.heroLarge};
  ${mediaQuery.widescreen} {
    font-size: ${Theme.sizes.text.hero};
  }
  ${mediaQuery.phone} {
    font-size: ${Theme.sizes.text.h1};
    line-height: 1.5;
  }
`;

const Hero = styled.div`
  ${withThemeColor};
  font-family: ${Theme.font.primary};
  font-size: ${Theme.sizes.text.hero};
  margin-bottom: 4rem;
  transition: all 0.3s;
  ${mediaQuery.desktop} {
    font-size: ${Theme.sizes.text.h1};
    margin-bottom: 2rem;
  }
  ${mediaQuery.tablet} {
    font-size: ${Theme.sizes.text.h2};
    margin-bottom: 1rem;
  }
`;

const Subtitle = styled.div`
  ${withThemeColor};

  font-family: Mont;
  font-size: ${Theme.sizes.text.subtitle};
  margin-bottom: 2rem;
  ${mediaQuery.phone} {
    font-size: ${Theme.sizes.text.p};
  }
`;

const h1 = styled.h1`
  ${withThemeColor};
  font-family: ${Theme.font.primary};
  font-size: ${Theme.sizes.text.h1};
  font-weight: normal;
  line-height: 4.8rem;
  margin-bottom: 6rem;
  ${mediaQuery.phone} {
    font-size: ${Theme.sizes.text.h3};
    line-height: 1.5;
    margin-bottom: 3rem;
  }
`;

const h2 = styled.h2`
  ${withThemeColor};
  font-family: ${Theme.font.primary};
  font-size: ${Theme.sizes.text.h2};
  font-weight: normal;
  margin-bottom: 6rem;
  ${mediaQuery.phone} {
    font-size: ${Theme.sizes.text.h4};
    margin-bottom: 4rem;
  }
`;

const h3 = styled.h3`
  ${withThemeColor};
  font-family: ${Theme.font.primary};
  font-size: ${Theme.sizes.text.h3};
  font-weight: normal;
`;

const h4 = styled.h4`
  ${withThemeColor};
  font-family: ${Theme.font.primary};
  font-size: ${Theme.sizes.text.h4};
  font-weight: normal;
`;

const h5 = styled.h5`
  ${withThemeColor};
  font-family: ${Theme.font.primary};
  font-size: ${Theme.sizes.text.h5};
  font-weight: normal;
`;

const sectionTitle = styled.div`
  ${withThemeColor};
  font-family: ${Theme.font.secondary};
  font-size: 2.2rem;
  font-weight: 600;
  margin-bottom: 2rem;
`;

const SafelySetInnerHtmlContainer = styled.div`
  color: inherit;
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: ${Theme.font.primary};
    font-weight: normal;
    font-size: 3.6rem;
    line-height: 4.8rem;
    margin-bottom: 2rem;
  }
  p {
    font-family: ${Theme.font.secondary};
    line-height: 3.6rem;
    text-align: justify;
    &:nth-of-type(n + 2) {
      padding-top: 2rem;
    }
  }
`;

const safeInner: FunctionComponent<any> = ({ children }) => (
  <SafelySetInnerHtmlContainer dangerouslySetInnerHTML={{ __html: children }} />
);

type TextType = FunctionComponent<{ color: string }> & {
  largeHero?: any;
  hero?: any;
  subtitle?: any;
  h1?: any;
  h2?: any;
  h3?: any;
  h4?: any;
  h5?: any;
  p?: any;
  sectionTitle?: any;
  safeInner?: any;
};

const Text: TextType = ({ children, ...rest }) => <BaseText {...rest}>{children}</BaseText>;

Text.largeHero = LargeHero;
Text.hero = Hero;
Text.subtitle = Subtitle;
Text.h1 = h1;
Text.h2 = h2;
Text.h3 = h3;
Text.h4 = h4;
Text.h5 = h5;
Text.p = BaseText;
Text.sectionTitle = sectionTitle;
Text.safeInner = safeInner;

export default Text;
