// Note Currency types

export type Currency = {
  symbol: string;
  rate: number;
};

export type CurrencyState = {
  selectedCurrency: Currency;
  availableCurrencies: { [index: string]: number };
  pending: boolean;
  fulfilled: boolean;
  rejected: boolean;
};

export const SET_CURRENCY = 'SET_CURRENCY';
export const SET_AVAILABLECURRENCIES = 'SET_AVAILABLECURRENCIES';
export const INITIALIZE_CURRENCY = 'INITIALIZE_CURRENCY';
export const INITIALIZE_CURRENCY_PENDING = 'INITIALIZE_CURRENCY_PENDING';
export const INITIALIZE_CURRENCY_FULFILLED = 'INITIALIZE_CURRENCY_FULFILLED';
export const INITIALIZE_CURRENCY_REJECTED = 'INITIALIZE_CURRENCY_REJECTED';

type SetCurrencyAction = {
  type: typeof SET_CURRENCY;
  payload: number;
};
type SetCurrencyPendingAction = {
  type: typeof INITIALIZE_CURRENCY_PENDING;
  payload: any;
};
type SetCurrencyFulfilledAction = {
  type: typeof INITIALIZE_CURRENCY_FULFILLED;
  payload: { selectedCurrency: { [key: string]: string }; availabelCurrencies: { [key: string]: string } };
};

type SetCurrencyRejectedAction = {
  type: typeof INITIALIZE_CURRENCY_REJECTED;
  payload: Error | any;
};

type SetAvailabelCurrencyAction = {
  type: typeof SET_AVAILABLECURRENCIES;
  payload: [Currency];
};

type InitializeCurrency = {
  type: typeof INITIALIZE_CURRENCY;
  payload: undefined;
};

export type CurrencyActionTypes = | SetCurrencyAction
  | SetAvailabelCurrencyAction
  | InitializeCurrency
  | SetCurrencyPendingAction
  | SetCurrencyFulfilledAction
  | SetCurrencyRejectedAction;

// Note user types

export type UserState = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  jobTitle: string;
  professionalSummary: string;
};

export const SET_FIRST_NAME = 'SET_FIRST_NAME';
export const SET_LAST_NAME = 'SET_LAST_NAME';
export const SET_EMAIL = 'SET_EMAIL';
export const SET_PHONE = 'SET_PHONE';
export const SET_JOB_TITLE = 'SET_JOB_TITLE';
export const SET_PROFESSIONAL_SUMMARY = 'SET_PROFESSIONAL_SUMMARY';

type SetFirstNameAction = {
  type: typeof SET_FIRST_NAME;
  payload: string;
};
type SetLastNameAction = {
  type: typeof SET_LAST_NAME;
  payload: string;
};
type SetEmailAction = {
  type: typeof SET_EMAIL;
  payload: string;
};
type SetPhoneAction = {
  type: typeof SET_PHONE;
  payload: string;
};
type SetJobTitleAction = {
  type: typeof SET_JOB_TITLE;
  payload: string;
};
type SetProfessionalSummaryAction = {
  type: typeof SET_PROFESSIONAL_SUMMARY;
  payload: string;
};

export type UserActionTypes = | SetFirstNameAction
  | SetLastNameAction
  | SetEmailAction
  | SetPhoneAction
  | SetJobTitleAction
  | SetProfessionalSummaryAction;
