import { css } from '@emotion/core';
import { mediaQuery } from './mediaQueries';

const sizes: { [key: string]: { [key: string]: string } } = {
  auto: {
    normal: '0 0',
    widescreen: '0 0',
    desktop: '0 4%',
    tablet: '0 4%',
    phone: '0 4%',
  },
  large: {
    normal: '0 4%',
    widescreen: '0 4%',
    desktop: '0 4%',
    tablet: '0 4%',
    phone: '0 4%',
  },
  small: {
    normal: '0 12%',
    widescreen: '0 12%',
    desktop: '0 4%',
    tablet: '0 4%',
    phone: '0 4%',
  },
};

type ContainerSizeProps = {
  size?: 'auto' | 'small' | 'large';
};

export const withContainerSize = ({ size = 'auto' }: ContainerSizeProps) => css`
  padding: ${sizes[size].normal};

  ${mediaQuery.widescreen} {
    padding: ${sizes[size].widescreen};
    /* background-color: blue; */
  }

  ${mediaQuery.desktop} {
    padding: ${sizes[size].desktop};
    /* background-color: green; */
  }
  ${mediaQuery.tablet} {
    padding: ${sizes[size].tablet};
    /* background-color: red; */
  }
  ${mediaQuery.phone} {
    padding: ${sizes[size].phone};
    /* background-color: yellow; */
  }
`;
