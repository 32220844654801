import React, { FunctionComponent } from 'react';
import styled from '@emotion/styled';
import { PrismicText, PrismicLayoutFooter, PrismicNavLink } from '@types';
import { Container } from '@templates';
import { Text } from '@atoms';
import Theme from '@theme';
import { NavBrand } from '@molecules';

const FooterLinksGroup = styled.div``;

const renderFooterLinks = ({ primary, id }: PrismicNavLink) => (
  <FooterLinksGroup key={id}>
    <Text.h5>{primary.label.text}</Text.h5>
  </FooterLinksGroup>
);

const FooterLowerIconWrapper = styled.div`
  flex-grow: 1;
  a {
    img {
      height: 10rem;
      position: absolute;
    }
  }
`;

const FooterLowerContainer = styled.div`
  background-color: ${Theme.colors.background};
  padding: 2rem 0;
  ${Container} {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    ${Text.p} {
      padding-right: 2rem;
      margin-bottom: 0;
      &:nth-last-of-type(1) {
        padding-right: 0;
      }
    }
  }
`;

const FooterContainer = styled.footer``;

export const Footer: FunctionComponent<PrismicLayoutFooter> = ({
  FooterNavLinks,
  footer_address,
  footer_company,
  footer_country,
  footer_icon,
  footer_telephone,
  footer_zip,
}) => (
  <FooterContainer>
    <FooterLowerContainer>
      <Container size="small">
        <FooterLowerIconWrapper>
          <NavBrand to="/" siteLogo={footer_icon} />
        </FooterLowerIconWrapper>
        {/* <Text.p>{footer_company.text}</Text.p>
          <Text.p>{footer_address.text}</Text.p>
          <Text.p>{footer_zip.text}</Text.p>
          <Text.p>{footer_country.text}</Text.p>
          <Text.p>{footer_telephone.text}</Text.p> */}
      </Container>
    </FooterLowerContainer>
  </FooterContainer>
);
