import styled from '@emotion/styled';
import { withContainerSize } from '@utils';

type ContainerProps = {
  size?: 'auto' | 'small' | 'large';
};

export const Container = styled.div<ContainerProps>`
  ${withContainerSize}
`;
