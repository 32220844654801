import styled from '@emotion/styled';
import { FunctionComponent } from 'react';
import { Label, Input } from '@atoms';

const InputFieldContainer = styled.div``;

type InputFieldProps = {
  label: string;
  placeHolder?: string;
  value: string;
  onChange: (newValue: string) => void;
};

export const InputField: FunctionComponent<InputFieldProps> = ({ label, placeHolder, value, onChange }) => {
  const handleOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    const newValue = event.currentTarget.value;
    onChange(newValue);
  };
  return (
    <InputFieldContainer>
      <Label htmlFor={label}>{label}</Label>
      <Input id={label} placeholder={placeHolder || label} value={value} onChange={handleOnChange} />
    </InputFieldContainer>
  );
};
