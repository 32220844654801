import React, { FunctionComponent } from 'react';
import styled from '@emotion/styled';
import { mediaQuery } from '@utils';
import classNames from 'classnames';

const navBurgerPaddingX = '1.5rem';
const navBurgerPaddingY = '1.5rem';
const navBurgerLayerWidth = '3rem';
const navBurgerActiveLayerWidth = '2rem';
const navBurgerLayerHeight = '0.3rem';
const navBurgerLayerSpacing = '0.6rem';
const navBurgerLayerColor = '#000';
const navBurgerLayerBorderRadius = '0';
const navBurgerActiveLayerColor = '#fff';

// .hamburger-box.
const NavBurgerBox = styled.div`
  width: ${navBurgerLayerWidth};
  height: calc(${navBurgerLayerHeight} * 3 + ${navBurgerLayerSpacing} * 2);
  display: inline-block;
  position: relative;
`;
// .hamburger-inner.
const NavBurgerInner = styled.div`
  display: block;
  margin-top: ${navBurgerLayerHeight} / -2;
  &,
  &::before,
  &::after {
    width: ${navBurgerLayerWidth};
    height: ${navBurgerLayerHeight};
    border-radius: ${navBurgerLayerBorderRadius};
    position: absolute;
  }
  &::before,
  &::after {
    content: '';
    display: block;
  }
  &::before {
    top: calc((${navBurgerLayerSpacing} + ${navBurgerLayerHeight}) * -1);
  }
  &::after {
    bottom: calc((${navBurgerLayerSpacing} + ${navBurgerLayerHeight}) * -1);
  }
  // Arrow alt
  &::before {
    transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  &::after {
    transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  &,
  &::before,
  &::after {
    background-color: ${navBurgerLayerColor};
    transition: transform 0.15s, background-color 0.3s ease 0.4s;
  }
`;

// .hamburger .haburger-arrowalt .js-hamburger.
const NavBurgerContainer = styled.div`
  padding: ${navBurgerPaddingY} ${navBurgerPaddingX};
  display: none;
  align-self: center;
  cursor: pointer;
  height: calc(${navBurgerLayerHeight} * 3 + ${navBurgerLayerSpacing} * 2);
  z-index: 2;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  -webkit-tap-highlight-color: transparent;
  ${mediaQuery.desktop} {
    display: inline-block;
  }
  &.is-active {
    ${NavBurgerInner} {
      &:before {
        width: ${navBurgerActiveLayerWidth};
        top: 0;
        transform: translate3d(calc(${navBurgerActiveLayerWidth} * -0.2), calc(${navBurgerActiveLayerWidth} * -0.25), 0)
          rotate(-45deg) scale(0.7, 1);
        transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
      }
      &:after {
        width: ${navBurgerActiveLayerWidth};
        bottom: 0;
        transform: translate3d(calc(${navBurgerActiveLayerWidth} * -0.2), calc(${navBurgerActiveLayerWidth} * 0.25), 0)
          rotate(45deg) scale(0.7, 1);
        transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
      }
      &,
      &::before,
      &::after {
        background-color: ${navBurgerActiveLayerColor};
        transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22),
          background-color 0.3s ease;
      }
    }
  }
`;

type NavBurgerProps = {
  isActive: boolean;
  onClick: () => void;
};

export const NavBurger: FunctionComponent<NavBurgerProps> = ({ isActive, onClick }) => {
  const className = classNames({ isActive });
  return (
    <NavBurgerContainer className={className} onClick={onClick}>
      <NavBurgerBox>
        <NavBurgerInner />
      </NavBurgerBox>
    </NavBurgerContainer>
  );
};
