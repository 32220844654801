import React, { FunctionComponent } from 'react';
import { Provider } from 'react-redux';
import configureStore, { actions } from '@redux';
import styled from '@emotion/styled';
import { StaticQuery, graphql } from 'gatsby';
import { PrismicLayout } from '@types';
import Helmet from 'react-helmet';
import GlobalStyles from '../../global/styles';
import { Navbar, Footer } from '@organisms';

const layoutQuery = graphql`
  query getPrismicLayout {
    prismicLayout {
      data {
        site_logo {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`;

const LayoutContainer = styled.div``;

const store = configureStore();

type LayoutProps = {
  prismicLayout: PrismicLayout;
  location?: any;
};

const Layout: FunctionComponent<LayoutProps> = ({ children, prismicLayout, location, ...allRest }) => {
  const { site_logo } = prismicLayout.data;
  const tempIsCvBuilder = location.pathname.includes('cvBuilder');
  return (
    <Provider store={store}>
      <LayoutContainer>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content="Ferilskrá" />
          <title>Ferilskrá.is</title>
        </Helmet>
        <GlobalStyles />
        {!tempIsCvBuilder && <Navbar HeaderNavLinks={[]} site_logo={site_logo} />}
        {children}
        {!tempIsCvBuilder && <Footer footer_icon={site_logo} />}
      </LayoutContainer>
    </Provider>
  );
};

type LayoutHOCProps = {};

const LayoutHOC: FunctionComponent<LayoutHOCProps> = ({ children, ...rest }) => (
  <StaticQuery
    query={layoutQuery}
    render={(data: { prismicLayout: PrismicLayout }) => (
      <Layout prismicLayout={data.prismicLayout} {...rest}>
        {children}
      </Layout>
    )}
  />
);

export default LayoutHOC;
