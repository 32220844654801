import React from 'react';
import { Global, css } from '@emotion/core';

const styles = css`
  @font-face {
    font-family: 'Njord';
    src: url('/fonts/Njord_alternate.otf');
  }
  @font-face {
    font-family: 'Mont';
    src: url('/fonts/Mont_Regular.otf');
  }
  @font-face {
    font-family: 'Milestone';
    src: url('/fonts/Milestone.ttf');
  }
  @font-face {
    font-family: 'Glades';
    src: url('/fonts/glades.ttf');
  }
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  }

  html {
    box-sizing: border-box;
    font-size: 62.5%; // 10px / 16px = 62.5% -> 1rem = 10px
  }

  body {
    font-family: Mont, sans-serif;
    color: #333;
    font-size: 1.6rem;
    overflow-x: hidden;
    overflow-y: auto;
    > * .tl-wrapper {
      z-index: initial !important;
    }
  }
`;

export default () => <Global styles={styles} />;
