import axios from 'axios';
import { Currency, SET_CURRENCY, SET_AVAILABLECURRENCIES, INITIALIZE_CURRENCY } from '@types';

export const setCurrency = (payload: string) => ({
  type: SET_CURRENCY,
  payload,
});

export const setAvailableCurrency = (payload: [Currency]) => ({
  type: SET_AVAILABLECURRENCIES,
  payload,
});

export const initializeCurrency = () => ({
  type: INITIALIZE_CURRENCY,
  payload: fetchExchangeData(),
});

const fetchExchangeData = () => new Promise(async (resolve, reject) => {
  try {
    const { data } = await axios('https://api.exchangeratesapi.io/latest?base=ISK');
    const { GBP, USD, EUR, ISK } = data.rates;
    const selectedCurrency = { symbol: 'USD', rate: USD };
    return resolve({ selectedCurrency, availableCurrencies: { GBP, USD, EUR, ISK } });
  } catch (error) {
    return reject(error);
  }
});
