import React, { FunctionComponent } from 'react';
import styled from '@emotion/styled';
import { mediaQuery } from '@utils';
import { Link } from 'gatsby';
import { SiteLogo } from '@types';

const NavBrandContainer = styled(Link)`
  display: flex;
  align-items: center;
  align-self: center;
  width: fit-content;
  cursor: pointer;
  ${mediaQuery.phone} {
    font-size: 1.4rem;
  }
`;

// Todo: Would be nice if the text in the logo was 100% center
const NavBrandLogo = styled.img`
  padding-bottom: 0.5rem;
  height: 9rem;
  ${mediaQuery.phone} {
    height: 5rem;
  }
`;

type NavBrandProps = {
  siteLogo: SiteLogo;
  to: string;
};

export const NavBrand: FunctionComponent<NavBrandProps> = ({ siteLogo, to, children }) => {
  if (!siteLogo) {
    throw new Error('Site logo is missing');
  }
  return (
    <NavBrandContainer to={to}>
      <NavBrandLogo alt={siteLogo.alt || 'Brand logo'} src={siteLogo.localFile.publicURL} />
    </NavBrandContainer>
  );
};
