type Sizes = Object & {
  widescreen: number;
  desktop: number;
  tablet: number;
  phone: number;
  [key: string]: number;
};

const sizes: Sizes = {
  widescreen: 1450,
  desktop: 992,
  tablet: 768,
  phone: 576,
};

type MediaQuery = {
  phone: () => any;
  tablet: () => any;
  desktop: () => any;
  widescreen: () => any;
  [key: string]: () => any;
};

export const mediaQuery = Object.keys(sizes).reduce(
  (obj: { [key: string]: string }, key) => {
    obj[key] = `@media (max-width: ${sizes[key] / 16}rem)`;
    return obj;
  },
  {} as { [key: string]: string },
);
