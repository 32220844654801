import styled from '@emotion/styled';
import { FunctionComponent } from 'react';
import { Label, TextArea } from '@atoms';

const TextAreaFieldContainer = styled.div``;

type TextAreaFieldProps = {
  label: string;
  placeHolder?: string;
  value: string;
  onChange: (newValue: string) => void;
};

export const TextAreaField: FunctionComponent<TextAreaFieldProps> = ({ label, placeHolder, value, onChange }) => {
  const handleOnChange = (event: React.FormEvent<HTMLTextAreaElement>) => {
    const newValue = event.currentTarget.value;
    onChange(newValue);
  };
  return (
    <TextAreaFieldContainer>
      <Label htmlFor={label}>{label}</Label>
      <TextArea id={label} placeholder={placeHolder || label} value={value} onChange={handleOnChange} />
    </TextAreaFieldContainer>
  );
};
