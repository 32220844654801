import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import promise from 'redux-promise-middleware';
import { CurrencyReducer, initialCurrencyState } from './currency/reducers';
import { UserReducer, initialUserState } from './user/reducers';
import * as currencyActions from './currency/actions';
import * as userActions from './user/actions';

// Todo: Replace this with get data from localStorage
const getInitialStore = () => ({
  user: initialUserState,
});

const rootReducer = combineReducers<any>({
  user: UserReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default () => {
  const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(promise)));
  return store;
};

export const actions = {
  currency: currencyActions,
  user: userActions,
};
