import { css } from '@emotion/core';
import { BaseTheme } from './theme.type';

const sizes = {
  mobile: '76.8rem',
  tablet: '76.9rem',
  desktop: '102.4rem',
  wide: '121.6rem',
  retina: '140.8rem',
};

const Theme: BaseTheme = {
  colors: {
    primary: '#6D48E5',
    secondary: '#DC1414',
    tertiary: '#e8f0fe',
    dark: '#000',
    grey: '#999999',
    background: '#fff',
    light: '#fefefe',
    lighter: '#dadada',
  },
  font: {
    primary: 'Poppins, sans-serif',
    secondary: 'Hind, sans-serif',
    tertiary: 'sans-serif, sans-serif',
    packageTitle: 'sans-serif, sans-serif',
  },
  sizes: {
    maxWidth: '132rem',
    gridGap: {
      small: '2rem',
      medium: '3rem',
      large: '14rem',
    },
    text: {
      heroLarge: '7.8rem',
      hero: '6.8rem',
      subtitle: '1.8rem',
      h1: '3.8rem',
      h2: '2.8rem',
      h3: '2.2rem',
      h4: '2rem',
      h5: '1.6rem',
      p: '1.6rem',
    },
  },
};

export default Theme;
