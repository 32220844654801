import React, { FunctionComponent, ReactChild } from 'react';
import styled from '@emotion/styled';
import Theme from '@theme';
import { Icon } from '@atoms';

const ButtonContainer = styled.a`
  font-size: 1.6rem;
  cursor: pointer;
  padding: 1.5rem 2rem;
  color: #000;
  border-radius: 0.1rem;
  display: flex;
  transition: color, background-color 0.3s ease-in-out;
  text-decoration: none;
  align-items: center;
  font-family: ${Theme.font.primary};
  color: inherit;
  background: inherit;
  > span {
    margin-right: 1rem;
  }
`;

type ButtonProps = {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
};

type ButtonWithIconProps = ButtonProps & {
  children?: ReactChild;
  icon: string;
};

export const Button: FunctionComponent<ButtonProps> = ({ onClick, ...rest }) => (
  <ButtonContainer onClick={onClick} {...rest} />
);

export const IconButton: FunctionComponent<ButtonWithIconProps> = ({ onClick, icon, children, ...rest }) => (
  <ButtonContainer onClick={onClick} {...rest}>
    {children && <span>{children}</span>}
    <Icon icon={icon} />
  </ButtonContainer>
);
