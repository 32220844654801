import styled from '@emotion/styled';
import Theme from '@theme';

export const Label = styled.label`
  font-size: 1.4rem;
  line-height: 3rem;
  font-weight: 500;
  font-family: ${Theme.font.secondary};
  margin-bottom: 1rem;
  color: ${Theme.colors.grey};
`;
