import React, { FunctionComponent } from 'react';
import styled from '@emotion/styled';
import { TextAreaField } from '@molecules';
import { Text } from '@atoms';
import Theme from '@theme';

const GridContainer = styled.div`
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${Theme.sizes.gridGap.medium};
`;

const ProfessionalSummaryContainer = styled.div``;

type ProfessionalSummaryProps = {
  onChange: (newValue: string) => void;
  value: string;
};
// Note This should be a rich text editor
export const ProfessionalSummary: FunctionComponent<ProfessionalSummaryProps> = ({ onChange, value }) => (
  <ProfessionalSummaryContainer>
    <Text.sectionTitle>Professional Summary</Text.sectionTitle>
    <GridContainer>
      <TextAreaField
        onChange={onChange}
        value={value}
        placeHolder="Write something smart!"
        label="Include 2-3 clear sentences about your overall experience"
      />
    </GridContainer>
  </ProfessionalSummaryContainer>
);
