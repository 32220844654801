import React, { FunctionComponent, useState } from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import { mediaQuery } from '@utils';
import { PrismicLayoutHeader, CurrencyState } from '@types';
import { NavBrand, NavBurger, NavLink } from '@molecules';
import { Container } from '@templates';
import { AppState, actions } from '@redux';

const NavItemOpenTransition = () => {
  let navItemTransitionList = '';
  for (let i = 1; i <= 10; i += 1) {
    navItemTransitionList += `
      &:nth-of-type(${i}) {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        transition-delay: ${0.05 * i + 0.2}s;
      }`;
  }
  return navItemTransitionList;
};

const NavItemCloseTransition = () => {
  let navItemTransitionList = '';
  for (let i = 1; i <= 10; i += 1) {
    navItemTransitionList += `
      &:nth-of-type(${i}) {
        transition-delay: ${0.05 * i}s;
      }`;
  }
  return navItemTransitionList;
};

const NavItemWrapper = styled.div`
  display: flex;
  margin-right: 8rem;

  ${mediaQuery.desktop} {
    padding-top: 12rem;
    padding-left: 2rem;
    transform: translate3d(100%, 0, 0);
    opacity: 0;
    transition: transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.4s;
    transition-delay: 0.4s;
    position: fixed;
    flex-direction: column;
    background: linear-gradient(152.87deg, #5204d1 0%, #9124ac 54.1%, #f44c4f 100%);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    > * {
      transform: translate3d(0, 6rem, 0);
      opacity: 0;
      transition: transform 1s, opacity 0.2s;
      ${NavItemCloseTransition()}
    }
  }
`;

type NavContainerProps = {
  withBackgroundColor?: boolean;
};

const NavContainer = styled.nav<NavContainerProps>`
  padding: 1.5rem;
  background: ${({ withBackgroundColor }) => (withBackgroundColor ? 'ghostwhite' : 'transparent')};
  transition: background 0.35s ease-in-out, z-index 0.1s ease 0.4s;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 99;
  &.is-active {
    z-index: 9;
    transition: z-index 0.1s ease;
    ${NavItemWrapper} {
      ${mediaQuery.desktop} {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition-delay: 0s;
        > * {
          transition: transform 0.4s, opacity 0.5s;
          ${NavItemOpenTransition()}
        }
      }
    }
  }
`;

const NavWrapper = styled(Container)`
  position: relative;
  display: flex;
  grid-area: nav;
  justify-content: space-between;
  z-index: 2;
  transition: background 0.35s ease-in-out, z-index 0.1s ease 0.4s;
  ${mediaQuery.phone} {
    padding-left: 1rem;
  }
  &.is-active {
    z-index: 9;
    transition: z-index 0.1s ease;
    ${NavItemWrapper} {
      ${mediaQuery.desktop} {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition-delay: 0s;
        > * {
          transition: transform 0.4s, opacity 0.5s;
          ${NavItemOpenTransition()}
        }
      }
    }
  }
`;

type NavbarProps = PrismicLayoutHeader & {
  withBackgroundColor?: boolean;
  currency: CurrencyState;
  setCurrency: typeof actions.currency.setCurrency;
};

const Navbar: FunctionComponent<NavbarProps> = ({
  withBackgroundColor,
  site_logo,
  HeaderNavLinks,
  currency,
  setCurrency,
}) => {
  const [isActive, setActive] = useState(false);
  const onNavBurgerClick = () => {
    setActive(!isActive);
  };

  return (
    <NavContainer withBackgroundColor>
      <NavWrapper size="large">
        <NavBrand to="/" siteLogo={site_logo} />
        <NavBurger isActive={isActive} onClick={onNavBurgerClick} />
      </NavWrapper>
    </NavContainer>
  );
};

const mapStateToProps = (state: AppState) => ({
  cart: state.cart,
  currency: state.currency,
});

export default connect(
  mapStateToProps,
  { setCurrency: actions.currency.setCurrency },
)(Navbar);
