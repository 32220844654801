import React from 'react';
import styled from '@emotion/styled';
import Theme from '@theme';

export const A4Container = styled.div`
  position: relative;
  width: 60vmax;
  height: 83.14vmax;
  border-radius: 1.2rem;
  padding: 5vmax;
  background-color: ${Theme.colors.light};
  box-shadow: 0 0.4rem 0.6rem rgba(0, 0, 0, 0.16);
`;
