import { SET_FIRST_NAME, SET_LAST_NAME, SET_EMAIL, SET_PHONE, SET_PROFESSIONAL_SUMMARY, SET_JOB_TITLE } from '@types';

export const setFirstName = (payload: string) => ({
  type: SET_FIRST_NAME,
  payload,
});
export const setLastName = (payload: string) => ({
  type: SET_LAST_NAME,
  payload,
});

export const setEmail = (payload: string) => ({
  type: SET_EMAIL,
  payload,
});

export const setPhone = (payload: string) => ({
  type: SET_PHONE,
  payload,
});
export const setJobTitle = (payload: string) => ({
  type: SET_JOB_TITLE,
  payload,
});
export const setProfessionalSummary = (payload: string) => ({
  type: SET_PROFESSIONAL_SUMMARY,
  payload,
});
