import React, { FunctionComponent } from 'react';
import styled from '@emotion/styled';
import { InputField } from '@molecules';
import { Text } from '@atoms';
import Theme from '@theme';

const GridContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${Theme.sizes.gridGap.medium};
`;

const PersonalDetailsContainer = styled.div``;

type PersonalDetailsProps = {
  firstName: { onChange: (newValue: string) => void; value: string };
  lastName: { onChange: (newValue: string) => void; value: string };
  email: { onChange: (newValue: string) => void; value: string };
  phone: { onChange: (newValue: string) => void; value: string };
  jobTitle: { onChange: (newValue: string) => void; value: string };
};

export const PersonalDetails: FunctionComponent<PersonalDetailsProps> = ({
  firstName,
  lastName,
  email,
  phone,
  jobTitle,
}) => (
  <PersonalDetailsContainer>
    <Text.sectionTitle>Personal Details</Text.sectionTitle>
    <GridContainer>
      <InputField label="First Name" {...firstName} />
      <InputField label="Last Name" {...lastName} />
      <InputField label="Email" {...email} />
      <InputField label="Phone" {...phone} />
      <InputField label="Job Title" {...jobTitle} />
    </GridContainer>
  </PersonalDetailsContainer>
);
