import React, { FunctionComponent } from 'react';
import styled from '@emotion/styled';
import Theme from '@theme';

import { mediaQuery } from '@utils';
import { Link } from 'gatsby';

const NavLinkContainer = styled(Link)`
  margin-right: 5rem;
  font-size: ${Theme.sizes.text.h5};
  display: flex;
  align-items: center;
  font-weight: bold;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  font-family: ${Theme.font.primary};
  color: ${Theme.colors.dark};
  &:visited,
  &:active {
    color: ${Theme.colors.dark};
  }
  ${mediaQuery.desktop} {
    font-weight: normal;
    color: #fff;
    font-size: 1.8rem;
    &:not(.primary) {
      margin-top: 2rem;
    }
  }
  &.primary {
    font-size: ${Theme.sizes.text.h4};
    margin-right: 6rem;
    color: #000;
    ${mediaQuery.desktop} {
      color: #fff;
      margin-bottom: 3rem;
      font-size: 2.8rem;
      margin-right: 0;
    }
  }
  &.isActive {
    &:after {
      content: '';
      bottom: 0.5rem;
      width: 50%;
      height: 0.4rem;
      position: absolute;
      background-color: ${Theme.colors.secondary};
    }
  }
`;

type NavLinkProps = {
  className?: string;
  primary?: boolean;
  to: string;
  activeClassName: string;
};

export const NavLink: FunctionComponent<NavLinkProps> = ({ className = '', children, primary, to, ...rest }) => {
  const navItemClass = `${className} ${primary ? 'primary' : ''}`;
  return (
    <NavLinkContainer to={to} className={navItemClass} {...rest}>
      {children}
    </NavLinkContainer>
  );
};
