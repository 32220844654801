import styled from '@emotion/styled';
import Theme from '@theme';

export const TextArea = styled.textarea`
  width: 100%;
  display: block;
  caret-color: ${Theme.colors.grey};
  background-color: ${Theme.colors.tertiary};
  color: ${Theme.colors.dark};
  font-family: ${Theme.font.secondary};
  font-size: 1.4rem;
  font-weight: 500;
  pointer-events: unset;
  border-radius: 0.3rem;
  padding: 1rem 1.6rem;
  outline: none;
  border-width: 0px;
  transition: color 0.1s ease 0s;
  resize: vertical;
  min-height: 12rem;
  max-height: 21rem;
  &::placeholder {
    color: ${Theme.colors.grey};
  }
`;
