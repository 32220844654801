import {
  UserState,
  UserActionTypes,
  SET_FIRST_NAME,
  SET_LAST_NAME,
  SET_EMAIL,
  SET_PHONE,
  SET_PROFESSIONAL_SUMMARY,
  SET_JOB_TITLE,
} from '@types';

export const initialUserState: UserState = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  jobTitle: '',
  professionalSummary: '',
};

export const UserReducer = (state = initialUserState, action: UserActionTypes) => {
  switch (action.type) {
    case SET_FIRST_NAME:
      return {
        ...state,
        firstName: action.payload,
      };

    case SET_LAST_NAME:
      return {
        ...state,
        lastName: action.payload,
      };

    case SET_EMAIL:
      return {
        ...state,
        email: action.payload,
      };

    case SET_PHONE:
      return {
        ...state,
        phone: action.payload,
      };

    case SET_JOB_TITLE:
      return {
        ...state,
        jobTitle: action.payload,
      };

    case SET_PROFESSIONAL_SUMMARY:
      return {
        ...state,
        professionalSummary: action.payload,
      };

    default:
      return state;
  }
};
